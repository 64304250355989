import { Form, Formik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { inviteNewISP } from "redux/actions/ispUsers";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import { registerNewISP } from "redux/actions/ispUsers";
import { createISPFromVenue } from "redux/actions/ispUsers";
import { fetchAllISPFromVenue } from "redux/actions/ispUsers";
import { createClient } from "redux/actions/client";
import { getAllClients } from "redux/actions/client";
import CustomerInviteComponent from "./customerInviteComponent";

function AddClientPage(props) {
    const { formId, formField } = form;
    const dispatch = useDispatch();
    const userdata = useSelector((state) => state.auth.userData);

    const handleSubmit = async (values, actions) => {
        const access_token = userdata.access;
        values.venue = Array.isArray(values.venue) ? values.venue : [values.venue];
        values.isp = Array.isArray(values.isp) ? values.isp : [values.isp];
        console.log(values, "access token in customer")
        // delete values.venue;
        dispatch(createClient(access_token, values))
        props.toogleModal();
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                    <CustomerInviteComponent formData={{ values, touched, formField, errors }} />
                    <VuiBox
                        display="flex"
                        justifyContent="center"
                        p={1.4}
                    >
                        <VuiButton
                            variant="contained"
                            color="error"
                            sx={{ width: '100%' }}
                            size="small"
                            disabled={isSubmitting}
                            type="submit"
                        >
                            Add New Customer
                        </VuiButton>
                    </VuiBox>
                </Form>
            )}
        </Formik>
    );

}

export default AddClientPage