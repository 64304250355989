import { useDispatch, useSelector } from "react-redux";
import ClientsTable from "./components/clientsTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VuiBox from "components/VuiBox";
import { useState, useEffect } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import VuiTypography from "components/VuiTypography";
import SelectTourplace from "pages/components/selectTourplace";
import { getTourplaceByISP } from "redux/actions/tourplace";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import { pushNotificationToClients, getAllClients } from "redux/actions/client";
import { alert_client_not_selected } from "redux/actions/warningMsgFunc";
import { alert_error_input_title } from "redux/actions/warningMsgFunc";
import { alert_error_input_content } from "redux/actions/warningMsgFunc";
import { Card, CardContent } from "@mui/material";
import { Modal } from "react-responsive-modal";
import ISPADDComponent from "pages/ispUsersManagementProgram/ispUserManagementProgramPage/components/ispAddComponent";
import { action_type } from "redux/action_type";
import AddClientPage from "./components/addCustomerComponent";

function ClientManagement() {
  const { values } = breakpoints;
  const dispatch = useDispatch();

  const [selectedTourplace, setSelectedTourplace] = useState();
  const userdata = useSelector((state) => state.auth.userData);
  const selectTourPlace = useSelector((state) => state.tourplaceReducer.tourplaceData);
  const selectedClients = useSelector((state) => state.clientReducer.selectedClientsData);

  const tourplacedata = selectTourPlace.map((item) => ({ value: item.id, label: item.place_name }));
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const access_token = userdata.access;

  const param = {
    start_row_index: 0,
    end_row_index: 9,
  };

  const addIspModalStatus = useSelector((state) => state.ispUsersReducer.addIspModalStatus);

  const toogleAddISPModal = () =>
    dispatch({ type: action_type.TOOGLE_ISP_ADD_MODAL, status: !addIspModalStatus });

  useEffect(() => {
    param.tourplace = selectedTourplace;
    dispatch(getAllClients(userdata.access, param));
    // dispatch(getTourplaceByISP(access_token));
  }, []);

  useEffect(() => {
    param.tourplace = selectedTourplace;
    dispatch(getAllClients(access_token, param));
  }, [selectedTourplace]);

  const sendNotification = () => {
    if (selectedClients.length < 1) {
      dispatch(alert_client_not_selected());
    } else if (title === "") {
      dispatch(alert_error_input_title());
    } else if (content === "") {
      dispatch(alert_error_input_content());
    } else {
      const param = {
        ids: selectedClients,
        title: title,
        content: content,
      };
      dispatch(pushNotificationToClients(access_token, param));
    }
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <ClientsTable tourplace={selectedTourplace} />
      </VuiBox>
      <Modal
        open={addIspModalStatus}
        center
        styles={{
          modal: { background: "#171a42", minWidth: "30%", marginTop: 100, maxWidth: "20%" },
          closeButton: { display: "none" },
        }}
        onClose={toogleAddISPModal}
      >
        <Card sx={{ minHeight: "490px" }}>
          <CardContent sx={{ padding: 0 }}>
            <AddClientPage toogleModal={toogleAddISPModal} />
          </CardContent>
        </Card>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

export default ClientManagement;
