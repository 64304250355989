import FormField from "pages/authentication/signup/components/FormField";
import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import SelectFormField from "./SelectFormField";

import { useDispatch, useSelector } from "react-redux";
import { getISPByVenueID } from "redux/actions/ispUsers";

function LoginInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { venue, isp, email, password } = formField;
  const { email: emailV, password: passwordV, tourplace: tourplaceV, isp: ispV } = values;

  const selectTourPlace = useSelector((state) => state.tourplaceReducer.tourplaceData);
  const tourplacedata = selectTourPlace.map((item) => ({ value: item.id, label: item.venue_name }));
  const ispByVenueID = useSelector((state) => state.ispUsersReducer.ispByVenueID);
  const ispdata =
    ispByVenueID?.isps?.length > 0
      ? ispByVenueID.isps.map((item) => ({ value: item.id, label: item.name }))
      : [];

  const dispatch = useDispatch();
  const handleChangeTourPlace = (e) => {
    dispatch(getISPByVenueID(e.value));
  };

  return (
    <>
      <VuiBox mb={2}>
        <SelectFormField
          label={venue.label}
          name={venue.name}
          type={venue.type}
          value={tourplaceV}
          selectArray={tourplacedata}
          placeholder={venue.placeholder}
          initialValue={0}
          onChange={(e) => {
            handleChangeTourPlace(e);
          }}
        />
      </VuiBox>
      <VuiBox mb={2}>
        <SelectFormField
          label={isp.label}
          name={isp.name}
          type={isp.type}
          value={ispV}
          selectArray={ispdata}
          placeholder={isp.placeholder}
          initialValue={0}
        />
      </VuiBox>
      <VuiBox mb={2}>
        <FormField
          label={email.label}
          name={email.name}
          type={email.type}
          value={emailV}
          placeholder={email.placeholder}
          error={errors.email && touched.email}
          success={emailV.length > 0 && !errors.email}
          inputProps={{ autoComplete: "" }}
        />
      </VuiBox>
      <VuiBox mb={2}>
        <FormField
          label={password.label}
          name={password.name}
          type={password.type}
          value={passwordV}
          placeholder={password.placeholder}
          error={errors.password && touched.password}
          success={passwordV.length > 0 && !errors.password}
          inputProps={{ autoComplete: "" }}
        />
      </VuiBox>
    </>
  );
}

// typechecking props for Address
LoginInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LoginInfo;
