/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

export default {
  formId: "new-isp-form",
  formField: {
    email: {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "example@example.com",
      errorMsg: "Email must be required",
    },
    username: {
      name: "username",
      label: "Username",
      type: "text",
      placeholder: "example",
      errorMsg: "Username must be required",
    },
    password: {
      name: "password",
      label: "password",
      type: "password",
      placeholder: "******",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    phone_number: {
      name: "phone_number",
      label: "Phone Number",
      type: "number",
      placeholder: "+1234567890",
      errorMsg: "Phone Number must be required",
    },
    tourplace: {
      name: "venue",
      label: "Venue",
      type: "string",
      placeholder: "eg. starter",
      errorMsg: "venue must be required.",
    },
  },
};
