import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

// @material-ui core components
import Card from "@mui/material/Card";
import ClientEditInfo from "./components";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { updateClient } from "redux/actions/client";
import { useNavigate } from "react-router-dom";
import validations from "./schemas/validations";
import { useEffect } from "react";
import { fetchAllTourPlace } from "redux/actions/tourplace";
import { getClientByID } from "redux/actions/client";
import { updateCustomer } from "redux/actions/customers";

// Settings page components

// Data
function ClientEditComponent(user) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCancelChange = () => {
    return navigate("/clientManagement");
  };
  const selectedClientData = useSelector((state) => state.clientReducer.selectedClientData);
  const selectTourPlace = useSelector((state) => state.tourplaceReducer.tourplaceData);
  const tourplacedata = selectTourPlace.map((item) => ({ value: item?.id, label: item?.venue_name }));
  const ispByVenueID = useSelector((state) => state.ispUsersReducer.ispByVenueID);
  const ispdata =
    ispByVenueID?.isps?.length > 0
      ? ispByVenueID.isps.map((item) => ({ value: item.id, label: item.name }))
      : [];

  const userdata = useSelector((state) => state.auth.userData);

  const handleSubmit = async (values, actions) => {
    values.id = user.user_id;
    values.venue = values.tourplace
    console.log(values, "values")
    dispatch(updateCustomer(userdata.access, values)).finally(() => {
      return navigate("/clientManagement");
    });
  };
  useEffect(() => {
    dispatch(fetchAllTourPlace());
    dispatch(getClientByID(userdata.access, user.user_id));
  }, [])

  const { formId, formField } = form;

useEffect(()=> {
  if (selectedClientData.length != 0) {
    initialValues.username = selectedClientData.username;
    initialValues.email = selectedClientData.email;
    initialValues.phone_number = selectedClientData.phone_number;
    // initialValues.venue_name = selectedClientData.venue_name
    initialValues.venue = selectedClientData?.venue?.map((venue) => venue.id) || [];
    initialValues.tourplace_display =
      selectedClientData?.venue?.map((venue) => ({
        value: venue?.id.toString(),
        label: venue?.venue_name.toString(),
      })) || [];
      initialValues.isp = selectedClientData?.venue?.map((venue) => venue.isp) || [];

      // ✅ Store ISP display data (if needed)
      initialValues.isp_display =
        selectedClientData?.venue?.map((venue) => ({
          value: venue?.isp_details?.id.toString(),
          label: venue?.isp_details?.username.toString(),
        })) || [];
  
    initialValues.status = selectedClientData.status;
  }
}, [selectedClientData])
    
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <VuiBox mb="40px">
        <VuiTypography variant="lg" color="white" fontWeight="bold">
          Edit Customer
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <Formik
          initialValues={initialValues}
          validationSchema={validations[0]}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <ClientEditInfo
                formData={{ values, touched, formField, errors, selectedClientData }}
                tourplacedata={tourplacedata}
                ispdata={ispdata}
              />
              <VuiBox
                display="flex"
                justifyContent="end"
                alignItems={{ sm: "flex-start", md: "center" }}
                flexDirection={{ sm: "column", md: "row" }}
                p={1.4}
              >
                <VuiBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                  <VuiButton
                    variant="outlined"
                    color="white"
                    size="small"
                    onClick={handleCancelChange}
                  >
                    Cancel
                  </VuiButton>
                  <VuiBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                    <VuiButton
                      variant="contained"
                      color="error"
                      sx={{ height: "100%" }}
                      size="small"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Save Change
                    </VuiButton>
                  </VuiBox>
                </VuiBox>
              </VuiBox>
            </Form>
          )}
        </Formik>
      </VuiBox>
    </Card>
  );
}

export default ClientEditComponent;
