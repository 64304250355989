import Card from "@mui/material/Card";
import DataTable from "./DataTable";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useDispatch, useSelector } from "react-redux";
import VuiBadgeDot from "components/VuiBadgeDot";
import ActionComponent from "./ActionComponent";
import { action_type } from "redux/action_type";

const BadgeComponent = (flag) => {
  return flag.flag ? (
    <VuiBox ml={-1.325}>
      <VuiBadgeDot size="xs" badgeContent="Verified" />
    </VuiBox>
  ) : (
    <VuiBox ml={-1.325}>
      <VuiBadgeDot size="xs" color="error" badgeContent="Non Verified" />
    </VuiBox>
  );
};

function ClientsTable({ tourplace }) {
  const users = useSelector((state) => state.clientReducer.clientsData);
  const dispatch = useDispatch();
  const userTableData = {
    columns: [
      { Header: "User Name", accessor: "username", align: "center" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Phone Number", accessor: "phone_number", align: "center" },
      { Header: "Venue", accessor: "venue" },
      { Header: "ISP", accessor: "isp" },
      { Header: "Action", accessor: "action", align: "center" },
      // { Header: "Email Verified", accessor: "is_active", align: 'center' },
    ],
    rows: [],
  };

  const addIspModalStatus = useSelector((state) => state.ispUsersReducer.addIspModalStatus);

  const handleAddbutton = () =>
    dispatch({ type: action_type.TOOGLE_ISP_ADD_MODAL, status: !addIspModalStatus });

  if (users.data) {

    userTableData.rows = users?.data?.customers?.map((user) => {
      return {
        ...user,
        // username: user.username
        venue: user.venue.map((item, key) => {
          return <li key={key}>{item.venue_name}</li>;
        }),
        isp: user.venue.map((item, key) => (
          <li key={key}>{item.isp_details?.username}</li>
        )),
        action: <ActionComponent user={user.id} />
        // is_active:(<BadgeComponent flag={user.status} />),
      };
    });
  }

  return (
    <VuiBox pt={6} pb={3}>
      <Card>
        <VuiBox p={3} pl={0} lineHeight={1}>
          <VuiTypography variant="h5" fontWeight="medium" color="white">
            Customer Table
          </VuiTypography>
        </VuiBox>
        <DataTable
          table={userTableData}
          canSearch
          pagination={{ variant: "gradient", color: "info" }}
          entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
          showTotalEntries={true}
          tourplace={tourplace}
          isSelectable={false}
          addHandleButtonChange={handleAddbutton}
        />
      </Card>
    </VuiBox>
  );
}

export default ClientsTable;
