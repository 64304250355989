import { ErrorMessage, Field } from "formik";

import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import VuiSelect from "components/VuiSelect";
import VuiTypography from "components/VuiTypography";
import { useFormikContext } from "formik";

function SelectFormField({ label, selectArray, initialValue, name, field, onChange = () => {}, value, ...rest }) {

    const { setFieldValue } = useFormikContext();
    // const handleOnChange = (e) => {
    //     setFieldValue(name, e.map(item => {
    //         return { value: item.value.toString(), label: item.label.toString() }
    //     }));
    // }

    const handleOnChange = (e) => {
        const selectedValues = e.map(item => item.value); // Extract only the values
        console.log(selectedValues, "selected values")
        setFieldValue(name, selectedValues); // Store as an array of IDs for API
        setFieldValue(`${name}_display`, e); // Store the display data as { value, label }
        onChange(e);
    };

    return (
        <VuiBox mb={1.5}>
            <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <VuiTypography
                    component="label"
                    variant="caption"
                    color="white"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {label}
                </VuiTypography>
            </VuiBox>
            <Field {...rest} name={name}>
                {({ field, form }) => (
                    <VuiSelect
                        defaultValue={initialValue}
                        value={value}   
                        isMulti
                        options={selectArray}
                        onChange={(e) => { handleOnChange(e) }}
                        size="small"
                    />
                )}
            </Field>
            <VuiBox mt={0.75}>
                <VuiTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={name} />
                </VuiTypography>
            </VuiBox>
        </VuiBox>
    );
}

// typechecking props for FormField
SelectFormField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
};

SelectFormField.defaultProps = {
    setEntriesPerPage: () => {},
};
export default SelectFormField;