import FormField from "pages/authentication/signup/components/FormField";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import OptionFormField from "./option_form_field";
import SelectFormField from "./select_form_field";
import { getISPByVenueID } from "redux/actions/ispUsers";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";

function ClientEditInfo({ formData, tourplacedata, ispdata }) {
  const { formField, values, errors, touched } = formData;
  const { username, email, phone_number, venue, isp } = formField;

  const {
    username: usernameV,
    email: emailV,
    phone_number: phone_numberV,
    venue: venueV,
    isp: ispV,
  } = values;

  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <FormField
          label={username.label}
          name={username.name}
          type={username.type}
          value={usernameV}
          placeholder={username.placeholder}
          error={errors.username && touched.username}
          success={usernameV.length > 0 && !errors.username}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          label={email.label}
          name={email.name}
          type={email.type}
          value={emailV}
          placeholder={email.placeholder}
          error={errors.email && touched.email}
          success={emailV.length > 0 && !errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          label={phone_number.label}
          name={phone_number.name}
          type={phone_number.type}
          value={phone_numberV}
          placeholder={phone_number.placeholder}
          error={errors.phone_number && touched.phone_number}
          success={phone_numberV.length > 0 && !errors.phone_number}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectFormField
          label={venue.label}
          name={venue.name}
          type={venue.type}
          value={values.tourplace_display} // Display data for UI
          initialValue={values.tourplace_display} // Display data for UI
          selectArray={tourplacedata}
          placeholder={venue.placeholder}
          onChange={(e) => {
            console.log(e[0]?.value, "e");
            setFieldValue("isp_display", ""); // Reset displayed ISP
            setFieldValue("isp", ""); // Reset actual ISP field
            dispatch(getISPByVenueID(e[0]?.value));
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectFormField
          label={isp.label}
          name={isp.name}
          type={isp.type}
          value={values.isp_display} // Display data for UI
          initialValue={values.isp_display} // Display data for UI
          selectArray={ispdata}
          placeholder={isp.placeholder}
        />
      </Grid>
    </Grid>
  );
}

// typechecking props for Address
ClientEditInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ClientEditInfo;
