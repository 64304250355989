import FormField from "./FormField";
import SelectFormField from "./SelectFormField";

import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getISPByVenueID } from "redux/actions/ispUsers";
import { fetchAllTourPlace } from "redux/actions/tourplace";

function CustomerInviteComponent({ formData }) {
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state.auth.userData);
  const selectTourPlace = useSelector((state) => state.tourplaceReducer.tourplaceData);
  const tourplacedata = selectTourPlace.map((item) => ({ value: item.id, label: item.venue_name }));
  const ispByVenueID = useSelector((state) => state.ispUsersReducer.ispByVenueID);
  const ispdata =
    ispByVenueID?.isps?.length > 0
      ? ispByVenueID.isps.map((item) => ({ value: item.id, label: item.name }))
      : [];

  const handleChangeTourPlace = (e) => {
    console.log(e, "e");
    dispatch(getISPByVenueID(e.value));
  };

  useEffect(() => {
    const access_token = userdata.access;
    dispatch(fetchAllTourPlace(access_token));
  }, []);

  const { formField, values, errors, touched } = formData;
  const { email, tourplace, username, phone_number, isp, password } = formField;
  const {
    email: email_V,
    username: username_V,
    phone_number: phone_number_V,
    tourplace: tourplace_V,
    isp: isp_V,
    password: password_V,
  } = values;

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <FormField
          label={username.label}
          name={username.name}
          type={username.type}
          value={username_V}
          placeholder={username.placeholder}
          error={errors.username && touched.username}
          success={username_V.length > 0 && !errors.username}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          label={email.label}
          name={email.name}
          type={email.type}
          value={email_V}
          placeholder={email.placeholder}
          error={errors.email && touched.email}
          success={email_V.length > 0 && !errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          label={phone_number.label}
          name={phone_number.name}
          type={phone_number.type}
          value={phone_number_V}
          placeholder={phone_number.placeholder}
          error={errors.phone_number && touched.phone_number}
          success={phone_number_V.length > 0 && !errors.phone_number}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          label={password.label}
          name={password.name}
          type={password.type}
          value={password_V}
          placeholder={password.placeholder}
          error={errors.password && touched.password}
          success={password_V.length > 0 && !errors.password}
          inputProps={{ autoComplete: "" }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SelectFormField
          label={tourplace.label}
          name={tourplace.name}
          type={tourplace.type}
          value={tourplace_V}
          selectArray={tourplacedata}
          placeholder={tourplace.placeholder}
          initialValue={0}
          onChange={(e) => {
            handleChangeTourPlace(e);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <SelectFormField
          label={isp.label}
          name={isp.name}
          type={isp.type}
          value={Array.isArray(isp_V) ? isp_V[0] : isp_V} // Ensure it's a number
          selectArray={ispdata}
          placeholder={isp.placeholder}
          initialValue={0}
        />
      </Grid>
    </Grid>
  );
}

// typechecking props for Address
CustomerInviteComponent.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CustomerInviteComponent;
