/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import * as Yup from "yup";

import checkout from "pages/clientManagementProgram/clientManagementProgramPage/components/addCustomerComponent/schemas/form";

const {
  formField: { email, username, phone_number, tourplace, isp, password },
} = checkout;

export default [
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [username.name]: Yup.string().required(username.errorMsg),
    [phone_number.name]: Yup.string().required(phone_number.errorMsg),
    [tourplace.name]: Yup.number().required(tourplace.errorMsg),
    [isp.name]: Yup.number().required(isp.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
  }),
];
