export default {
  formId: "login-form",
  formField: {
    venue: {
      name: 'venue',
      label: 'Venue',
      type: 'number',
      placeholder: "******",
      errorMsg: "Venue must be selected.",
      invalidMsg: "Venue must be selected.",
    },
    isp: {
      name: 'isp',
      label: 'ISP',
      type: 'number',
      placeholder: "******",
      errorMsg: "ISP must be selected.",
      invalidMsg: "ISP must be selected.",
    },
    email: {
      name: "email",
      label: "email address",
      type: "email",
      placeholder: "eg. vision@dashboard.come",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "password",
      type: "password",
      placeholder: "******",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    }
  }
};
