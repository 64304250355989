import FormField from "pages/cameraProgram/components/formField/index";
import SelectFormField from "pages/cameraProgram/components/formField/select_form_field";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTourplaceByISPID } from "redux/actions/tourplace";

function CameraComponent({ formData }) {
  const userdata = useSelector((state) => state.auth.userData);
  const access_token = userdata.access;
  const { formField, values, errors, touched } = formData;
  const tourplaceData = useSelector((state) => state.tourplaceReducer.tourplaceData);

  const tourplaceDataForm = tourplaceData?.venues?.map((item) => ({
    value: item?.id,
    label: item?.name,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTourplaceByISPID(access_token, userdata.user_id));
  }, []);

  const { camera_name, venue, rtsp_url } = formField;

  const { camera_name: camera_name_V, venue: tourplace_V, rtsp_url: rtsp_url_V } = values;

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <FormField
          label={camera_name.label}
          name={camera_name.name}
          type={camera_name.type}
          value={camera_name_V}
          placeholder={camera_name.placeholder}
          error={errors.camera_name && touched.camera_name}
          success={camera_name_V.length > 0 && !errors.camera_name}
        />
        <SelectFormField
          label={venue.label}
          name={venue.name}
          type={venue.type}
          value={tourplace_V}
          selectArray={tourplaceDataForm}
          placeholder={venue.placeholder}
          initialValue={0}
        />
        <FormField
          label={rtsp_url.label}
          name={rtsp_url.name}
          type={rtsp_url.type}
          value={rtsp_url_V}
          placeholder={rtsp_url.placeholder}
          error={errors.rtsp_url && touched.rtsp_url}
          success={rtsp_url_V.length > 0 && !errors.rtsp_url}
        />
      </Grid>
    </Grid>
  );
}

// typechecking props for Address
CameraComponent.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CameraComponent;
